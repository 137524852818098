import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  useTheme,
  Fade,
  Zoom,
  Paper,
  LinearProgress,
  IconButton,
  Collapse,
} from '@mui/material';
import confetti from 'canvas-confetti';
import { useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import { CheckCircleIcon } from '@/assets/icons/CheckCircleIcon';
import { ChevronDownIcon } from '@/assets/icons/ChevronDownIcon';
import { OnboardingRoutes, PMPRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { getResolvedRoutePath } from '@/utils/router';
import { getFirstName } from '@/utils/user';

import LoadingState from '@/components/LoadingState';
import TerriLottie from '@/components/lottie/TerriLottie';

// Define checklist item interface
interface ChecklistItem {
  title: string;
  description: string;
  isCompleted: boolean;
  actionLabel: string;
  route: string;
  isDisabled?: boolean;
  icon?: string;
  benefits?: string[];
}

interface StepItemProps {
  item: ChecklistItem;
  index: number;
  isExpandedItem: string | null;
  isNextItem: boolean;
  onToggleExpand: (title: string) => void;
}

// Define the StepItem component outside of the ChecklistPage component
const StepItem = ({ item, index, isExpandedItem, isNextItem, onToggleExpand }: StepItemProps) => {
  const theme = useTheme();
  const isExpanded = isExpandedItem === item.title;

  const getTitleColor = () => {
    if (item.isCompleted) return 'text.disabled';
    if (isNextItem) return 'primary';
    return undefined;
  };

  const getTextColor = () => {
    if (item.isCompleted) return 'text.disabled';
    return undefined;
  };

  return (
    <div
      style={{
        opacity: 1,
        animation: 'growIn 0.5s ease-out',
      }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 2,
          borderRadius: 2,
          mb: 2,
          transition: 'all 0.3s ease',
          border: isNextItem
            ? `2px solid ${theme.palette.primary.main}`
            : `1px solid ${theme.palette.divider}`,
          position: 'relative',
          overflow: 'hidden',
          '&:after': isNextItem
            ? {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '4px',
              background: theme.palette.primary.main,
            }
            : {},
        }}
      >
        <Stack direction="row" spacing={2} alignItems="flex-start">
          <Box sx={{ position: 'relative', mt: 1 }}>
            {item.isCompleted ? (
              <Zoom in>
                <Box component="span" display="block">
                  <CheckCircleIcon color="primary" fontSize="medium" />
                </Box>
              </Zoom>
            ) : (
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  borderRadius: '50%',
                  border: `2px solid ${
                    isNextItem ? theme.palette.primary.main : theme.palette.grey[400]
                  }`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: isNextItem ? theme.palette.primary.main : theme.palette.grey[700],
                  fontWeight: 'bold',
                  fontSize: '14px',
                }}
              >
                {index + 1}
              </Box>
            )}
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
              <Box>
                <Typography
                  variant="subtitle1"
                  color={getTitleColor()}
                  mb={0.5}
                  sx={{ fontWeight: isNextItem ? 600 : 500 }}
                >
                  {item.title}
                  {isNextItem && (
                    <Box
                      component="span"
                      sx={{
                        ml: 1,
                        fontSize: '12px',
                        color: 'white',
                        backgroundColor: theme.palette.primary.main,
                        px: 1,
                        py: 0.5,
                        borderRadius: '12px',
                        display: 'inline-flex',
                        alignItems: 'center',
                        fontWeight: 500,
                      }}
                    >
                      Next Step
                    </Box>
                  )}
                </Typography>
                <Typography variant="body2" color={getTextColor()} sx={{ maxWidth: '90%' }}>
                  {item.description}
                </Typography>
              </Box>

              <Stack direction="row" spacing={1} alignItems="center">
                <IconButton
                  size="small"
                  onClick={() => onToggleExpand(item.title)}
                  sx={{
                    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                  }}
                >
                  <ChevronDownIcon fontSize="small" />
                </IconButton>

                <Button
                  variant={isNextItem ? 'contained' : 'outlined'}
                  color={isNextItem ? 'primary' : 'secondary'}
                  size="small"
                  href={item.route}
                  disabled={item.isDisabled || item.isCompleted}
                >
                  {item.isCompleted ? 'Completed' : item.actionLabel}
                </Button>
              </Stack>
            </Stack>

            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  bgcolor: 'transparent',
                  borderLeft: `4px solid ${theme.palette.grey[200]}`,
                  pl: 3,
                  borderRadius: 0,
                }}
              >
                <Typography variant="subtitle2" mb={1}>
                  Why this matters:
                </Typography>
                <ul style={{ margin: 0, paddingLeft: '20px' }}>
                  {item.benefits?.map(benefit => (
                    <li
                      key={benefit}
                      style={{
                        opacity: 1,
                        animation: 'fadeIn 0.5s ease',
                      }}
                    >
                      <Typography variant="body2" gutterBottom>
                        {benefit}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            </Collapse>
          </Box>
        </Stack>
      </Paper>
    </div>
  );
};

const ChecklistPage: React.FC = () => {
  const { isOnboardingComplete, onboardingProgress, isFetching, refetch } = useOnboardingProgress();
  const { user } = useAuth();
  const theme = useTheme();
  const { selectedPropertyLocation, isSingleFamilyLocation } = usePropertyManager();
  const [expandedItem, setExpandedItem] = useState<string | null>(null);
  const [lastCompletedItem, setLastCompletedItem] = useState<string | null>(null);

  // Define keyframe animation styles
  const keyframeStyles = `
    @keyframes fadeIn {
      from { opacity: 0; transform: translateX(-10px); }
      to { opacity: 1; transform: translateX(0); }
    }
    @keyframes growIn {
      from { opacity: 0; transform: scale(0.95); }
      to { opacity: 1; transform: scale(1); }
    }
    @keyframes pulse {
      0% { opacity: 0.7; }
      50% { opacity: 1; }
      100% { opacity: 0.7; }
    }
  `;

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Trigger confetti when an item is completed
  useEffect(() => {
    if (lastCompletedItem) {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        colors: [theme.palette.primary.main, theme.palette.secondary.main, '#ffffff'],
      });

      // Reset after animation
      const timer = setTimeout(() => {
        setLastCompletedItem(null);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [lastCompletedItem, theme.palette.primary.main, theme.palette.secondary.main]);

  // Define image paths for steps
  const imagePaths = useMemo(
    () => ({
      profile: '/assets/images/onboarding/profile.svg',
      property: '/assets/images/onboarding/property.svg',
      tenants: '/assets/images/onboarding/tenants.svg',
      phone: '/assets/images/onboarding/phone.svg',
      email: '/assets/images/onboarding/email.svg',
      document: '/assets/images/onboarding/document.svg',
      building: '/assets/images/onboarding/building.svg',
      unit: '/assets/images/onboarding/unit.svg',
    }),
    []
  );

  // Common checklist items across all property types
  const commonChecklistItems = useMemo(
    (): ChecklistItem[] => [
      {
        title: 'Complete personal details',
        description: 'Complete your profile.',
        isCompleted: !!user?.user_metadata.full_name,
        actionLabel: 'Update Profile',
        route: PMPRoutes.account,
        icon: imagePaths.profile,
        benefits: [
          'Personalize your experience',
          'Help us tailor recommendations to your needs',
          "Allow tenants to know who they're working with",
        ],
      },
      {
        title: 'Find your property',
        description: 'Add your property details.',
        isCompleted: !!onboardingProgress.hasPropertyLocation,
        actionLabel: 'Add Property',
        route: OnboardingRoutes.propertyLocationCreate,
        icon: imagePaths.property,
        benefits: [
          'Organize your rental portfolio',
          'Access property-specific insights and tools',
          'Enable location-based features and reporting',
        ],
      },
      {
        title: 'Add tenant details',
        description: 'Add tenant details to manage your property effectively.',
        isCompleted: !!onboardingProgress.hasTenant,
        actionLabel: 'Add Tenants',
        route: `${getResolvedRoutePath(PMPRoutes.unitViewTenantList, {
          id: onboardingProgress.firstUnitId?.toString() ?? '',
        })}?openAddTenant=true`,
        isDisabled: !!onboardingProgress.hasTenant || !onboardingProgress.firstUnitId,
        icon: imagePaths.tenants,
        benefits: [
          'Track tenant information in one place',
          'Streamline communication with tenants',
          'Enable rent collection and maintenance requests',
        ],
      },
      {
        title: 'Get property phone number',
        description:
          'Tenants, vendors, and other contacts will use this as the main phone number for your property.',
        isCompleted: !!onboardingProgress.hasPhoneNumber,
        actionLabel: 'Get Phone Number',
        route: getResolvedRoutePath(PMPRoutes.propertyLocationPhoneCreate, {
          id: selectedPropertyLocation?.id?.toString() ?? '',
        }),
        isDisabled: !selectedPropertyLocation?.id || !onboardingProgress.hasTenant,
        icon: imagePaths.phone,
        benefits: [
          'Create a dedicated property contact line',
          'Keep personal and business communications separate',
          'Provide professional contact information to tenants and vendors',
        ],
      },
      {
        title: 'Send tenant onboarding email',
        description:
          'Invite your tenant to set up their account and access the portal. This allows them to pay their rent and look at their communication history.',
        isCompleted: !!onboardingProgress.hasTenantsWithOnboardingEmailSent,
        actionLabel: 'Send Email',
        route: `${getResolvedRoutePath(PMPRoutes.propertyLocationView, {
          id: selectedPropertyLocation?.id?.toString() ?? '',
        })}?openInviteTenant=true`,
        isDisabled: !onboardingProgress.hasTenant,
        icon: imagePaths.email,
        benefits: [
          'Enable tenant self-service features',
          'Simplify rent collection and maintenance requests',
          'Establish clear communication channels',
        ],
      },
    ],
    [user, onboardingProgress, selectedPropertyLocation, imagePaths]
  );

  // Property-specific checklist items
  const propertySpecificItems = useMemo((): ChecklistItem[] => {
    if (isSingleFamilyLocation) {
      return [
        {
          title: 'Upload lease document',
          description: 'Upload the lease agreement for your tenant.',
          isCompleted: !!onboardingProgress.hasLeaseDocument,
          actionLabel: 'Upload Lease',
          route: `${PMPRoutes.vault}?openDocumentUpload=true&defaultCategoryId=2`,
          isDisabled: !onboardingProgress.hasTenant,
          icon: imagePaths.document,
          benefits: [
            'Securely store important lease documents',
            'Easily reference terms and conditions',
            'Keep a digital record of all agreements',
          ],
        },
      ];
    }

    return [
      {
        title: 'Add building',
        description:
          'Add your building details to manage your property effectively. This helps organize your rental properties.',
        isCompleted: !!onboardingProgress.hasBuilding,
        actionLabel: 'Add Building',
        route: `${getResolvedRoutePath(PMPRoutes.propertyLocationViewBuildingList, {
          id: selectedPropertyLocation?.id?.toString() ?? '',
        })}?openAddBuilding=true`,
        isDisabled: !!onboardingProgress.hasBuilding || !selectedPropertyLocation?.id,
        icon: imagePaths.building,
        benefits: [
          'Organize multi-unit properties efficiently',
          'Group units for better management',
          'Enable building-specific insights and reports',
        ],
      },
      {
        title: 'Add unit that tenants live in',
        description:
          'Add units in your building to manage your tenants effectively. This helps track tenant occupancy.',
        isCompleted: !!onboardingProgress.hasUnit,
        actionLabel: 'Add Unit',
        route: `${getResolvedRoutePath(PMPRoutes.buildingViewUnitList, {
          id: onboardingProgress.firstBuildingId?.toString() ?? '',
        })}?openAddUnit=true`,
        isDisabled: !!onboardingProgress.hasUnit || !onboardingProgress.firstBuildingId,
        icon: imagePaths.unit,
        benefits: [
          'Track individual rental units separately',
          'Assign tenants to specific units',
          'Monitor unit-specific maintenance and financials',
        ],
      },
    ];
  }, [isSingleFamilyLocation, onboardingProgress, selectedPropertyLocation, imagePaths]);

  // Combine common and property-specific items
  const items = useMemo(() => {
    const result = [...commonChecklistItems];

    // Find tenant details and email onboarding indices
    const tenantItemIndex = result.findIndex(item => item.title === 'Add tenant details');
    const emailItemIndex = result.findIndex(item => item.title === 'Send tenant onboarding email');

    if (tenantItemIndex > -1 && emailItemIndex > -1) {
      // For multi-family locations, place building and unit items after property creation
      // but before tenant details
      if (!isSingleFamilyLocation && propertySpecificItems.length > 0) {
        result.splice(tenantItemIndex, 0, ...propertySpecificItems);
      }

      // For single-family locations, place lease item after tenant details
      if (isSingleFamilyLocation && propertySpecificItems.length > 0) {
        result.splice(emailItemIndex, 0, ...propertySpecificItems);
      }
    }

    return result;
  }, [commonChecklistItems, propertySpecificItems, isSingleFamilyLocation]);

  const incompleteItems = useMemo(() => items.filter(item => !item.isCompleted), [items]);
  const nextItem = useMemo(() => incompleteItems[0], [incompleteItems]);
  const completionPercentage = Math.round(
    ((items.length - incompleteItems.length) / items.length) * 100
  );

  // Auto-expand the active step
  useEffect(() => {
    if (nextItem) {
      setExpandedItem(nextItem.title);
    }
  }, [nextItem]);

  if (isOnboardingComplete) return <Redirect to={PMPRoutes.dashboard} />;

  const handleToggleExpand = (title: string) => {
    setExpandedItem(expandedItem === title ? null : title);
  };

  return (
    <Stack direction="column" spacing={3} sx={{ flex: '1 1 100%' }}>
      <style>{keyframeStyles}</style>

      <DashboardHeader
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="h5">{`Welcome, ${getFirstName(user)}`}</Typography>
            <TerriLottie size={32} sx={{ mt: -0.5 }} />
          </Box>
        }
        size="small"
      />

      {isFetching || !user ? (
        <LoadingState />
      ) : (
        <Card>
          <CardHeader
            title={
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="subtitle1">Setting Up Your Property</Typography>
                <Box
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    bgcolor: 'transparent',
                    borderRadius: '12px',
                    px: 1.5,
                    py: 0.5,
                    border: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <Typography variant="body2">
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      component="span"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {completionPercentage}%
                    </Typography>{' '}
                    Complete
                  </Typography>
                </Box>
              </Stack>
            }
            sx={{
              borderBottom: '1px solid',
              borderColor: 'divider',
              pr: 4,
              pl: 3,
            }}
          />
          <LinearProgress
            variant="determinate"
            value={completionPercentage}
            sx={{
              height: 4,
              borderRadius: 0,
              '& .MuiLinearProgress-bar': {
                transition: 'transform 1s ease-in-out',
              },
            }}
          />
          <CardContent sx={{ p: 3 }}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Complete these steps to fully set up your property management system. Each step
                unlocks new features to help you manage your properties more effectively.
              </Typography>
            </Box>

            <Stack direction="column" spacing={0.5}>
              {items.map((item, index) => (
                <StepItem
                  key={item.title}
                  item={item}
                  index={index}
                  isExpandedItem={expandedItem}
                  isNextItem={item === nextItem}
                  onToggleExpand={handleToggleExpand}
                />
              ))}
            </Stack>

            {completionPercentage >= 75 && completionPercentage < 100 && (
              <Fade in timeout={1000}>
                <Box
                  sx={{
                    mt: 3,
                    p: 2,
                    borderRadius: 2,
                    bgcolor: 'rgba(84, 214, 44, 0.12)',
                    color: 'success.dark',
                    border: `1px solid ${theme.palette.success.light}`,
                  }}
                >
                  <Typography variant="subtitle2">Almost there!</Typography>
                  <Typography variant="body2">
                    You're making great progress! Just a few more steps to fully set up your
                    property management system.
                  </Typography>
                </Box>
              </Fade>
            )}

            {completionPercentage === 100 && (
              <Fade in timeout={1000}>
                <Box
                  sx={{
                    mt: 3,
                    p: 2,
                    borderRadius: 2,
                    bgcolor: 'rgba(84, 214, 44, 0.12)',
                    color: 'success.dark',
                    border: `1px solid ${theme.palette.success.light}`,
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="h6">Congratulations! 🎉</Typography>
                  <Typography variant="body2">
                    You've completed all setup steps. Your property management system is now ready!
                  </Typography>
                </Box>
              </Fade>
            )}
          </CardContent>
        </Card>
      )}
    </Stack>
  );
};

export default ChecklistPage;
