import { FormHelperText, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import { ApiResponse_AssignPhoneNumber_PropertyLocation } from '@/api/endpoints/propertyLocation/useAssignPhoneNumber';
import {
  ApiRequest_GetAllAvailablePhoneNumbers_PropertyLocation,
  ApiResponse_GetAllAvailablePhoneNumbers_PropertyLocation,
  useGetAllAvailablePhoneNumbers,
} from '@/api/endpoints/propertyLocation/useGetAllAvailablePhoneNumbers';
import {
  ApiResponse_GetAllOwnedAndPublicPhoneNumbers_PropertyManager,
  useGetAllOwnedAndPublicPhoneNumbers,
} from '@/api/endpoints/propertyLocationPhone/useGetAllOwnedAndPublicPhoneNumbers';
import { PMPRoutes } from '@/config/routes';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';
import { getNextOnboardingStep } from '@/utils/onboarding';
import { formatPhoneNumber } from '@/utils/phone';

import FormTextField from '@/components/form/FormTextField';
import LoadingState from '@/components/LoadingState';
import PropertyLocationPhoneNumberAssignModal from '@/components/propertyLocation/PropertyLocationPhoneNumberSelectModal';

const PropertyLocationPhoneCreatePage: React.FC = () => {
  const { id } = useParams<ExtractParams<PMPRoutes.propertyLocationPhoneCreate>>();
  const history = useHistory();
  const { onboardingProgress } = useOnboardingProgress();

  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string | null>(null);
  const [isPhoneNumberSelectModalOpen, setIsPhoneNumberSelectModalOpen] = useState(false);

  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useForm<ApiRequest_GetAllAvailablePhoneNumbers_PropertyLocation>();

  const contains = watch('contains');

  const getAllAvailablePhoneNumbersQuery = useGetAllAvailablePhoneNumbers(
    Number(id),
    { contains },
    !!contains && contains.length > 1
  );

  const getAllOwnedAndPublicPhoneNumbersQuery = useGetAllOwnedAndPublicPhoneNumbers();

  const onSelectPhoneNumberClick = useCallback((phoneNumber: string) => {
    setSelectedPhoneNumber(phoneNumber);
    setIsPhoneNumberSelectModalOpen(true);
  }, []);

  const onPhoneNumberSelected = useCallback(
    (propertyLocation: ApiResponse_AssignPhoneNumber_PropertyLocation) => {
      history.replace(
        ...getNextOnboardingStep(PMPRoutes.propertyLocationPhoneCreate, {
          ...onboardingProgress,
          hasPhoneNumber: !!propertyLocation.phone?.value,
        })
      );
    },
    [history, onboardingProgress]
  );

  const ownedAndPublicColumns: GridColDef<
    ApiResponse_GetAllOwnedAndPublicPhoneNumbers_PropertyManager[0]
  >[] = useMemo(
    () => [
      {
        field: 'value',
        headerName: 'Phone Number',
        flex: 1,
        valueFormatter: (value: string) => formatPhoneNumber(value),
      },
      { field: 'type', headerName: 'Type', flex: 1 },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        width: 100,
        renderCell: (
          params: GridRenderCellParams<
            ApiResponse_GetAllOwnedAndPublicPhoneNumbers_PropertyManager[0]
          >
        ) => (
          <LoadingButton
            aria-label="Select Phone Number"
            size="small"
            color="success"
            onClick={() => onSelectPhoneNumberClick(params.row.value)}
          >
            Select
          </LoadingButton>
        ),
      },
    ],
    [onSelectPhoneNumberClick]
  );

  const purchasableColumns: GridColDef<
    ApiResponse_GetAllAvailablePhoneNumbers_PropertyLocation[0]
  >[] = useMemo(
    () => [
      {
        field: 'phoneNumber',
        headerName: 'Phone Number',
        flex: 1,
        valueFormatter: (value: string) => formatPhoneNumber(value),
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        width: 100,
        renderCell: (
          params: GridRenderCellParams<ApiResponse_GetAllAvailablePhoneNumbers_PropertyLocation[0]>
        ) => (
          <LoadingButton
            aria-label="Select Phone Number"
            size="small"
            color="success"
            onClick={() => onSelectPhoneNumberClick(params.row.phoneNumber)}
          >
            Select
          </LoadingButton>
        ),
      },
    ],
    [onSelectPhoneNumberClick]
  );

  const PhoneNumberListNoRowsOverlay = useCallback(
    () => (
      <NoRowsOverlay
        entityName="Phone Number"
        detailsText={
          'Search by digits, area code, prefix, phrases, or characters you want in your phone number.\nEnter at least 2 characters.'
        }
      />
    ),
    []
  );

  if (getAllOwnedAndPublicPhoneNumbersQuery.isLoading) return <LoadingState />;

  return (
    <Stack
      direction="column"
      sx={{ flex: '1 1 100%', height: '100%', display: 'flex' }}
      spacing={3}
    >
      <Stack spacing={1.5}>
        <DashboardHeader
          breadcrumbs={[{ label: 'Locations', to: PMPRoutes.propertyLocationList }]}
          title="Pick a Dedicated Number for Your Property"
        />

        <Typography variant="body1">
          This will be the main contact number for your property, used by tenants, vendors, and
          others who need to reach you.
        </Typography>
      </Stack>

      {!!getAllOwnedAndPublicPhoneNumbersQuery.data?.length && (
        <Stack spacing={1.5} flex="1 0 auto">
          <Typography variant="h5">Public & Owned Phone Numbers</Typography>

          <DataGrid
            rows={getAllOwnedAndPublicPhoneNumbersQuery.data ?? []}
            columns={ownedAndPublicColumns}
            density="comfortable"
            disableColumnFilter
            disableColumnMenu
            disableEval
            disableRowSelectionOnClick
            loading={getAllOwnedAndPublicPhoneNumbersQuery.isLoading}
            slots={{
              noRowsOverlay: PhoneNumberListNoRowsOverlay,
            }}
            slotProps={{
              loadingOverlay: {
                variant: 'skeleton',
                noRowsVariant: 'skeleton',
              },
            }}
            getRowId={row => row.id}
            getRowHeight={() => 'auto'}
          />
        </Stack>
      )}

      <Stack spacing={1.5} flex="1 0 auto">
        <Typography variant="h5">Purchasable Phone Numbers</Typography>

        <Stack component="form" spacing={1.5} maxWidth={400}>
          <FormTextField
            label="Search criteria"
            placeholder="Search by digits or phrases"
            error={!!errors.contains}
            helperText={errors.contains?.message?.toString()}
            tooltipText="Search by digits, area code, prefix, phrases, or characters you want in your phone number."
            required
            {...register('contains', {
              required: 'Search criteria is required',
              minLength: 2,
              maxLength: 10,
            })}
            size="small"
            control={control}
          />

          {!!getAllAvailablePhoneNumbersQuery.isError && (
            <FormHelperText error>{getAllAvailablePhoneNumbersQuery.error?.message}</FormHelperText>
          )}
        </Stack>

        <DataGrid
          rows={getAllAvailablePhoneNumbersQuery.data ?? []}
          columns={purchasableColumns}
          density="comfortable"
          disableColumnFilter
          disableColumnMenu
          disableEval
          disableRowSelectionOnClick
          loading={getAllAvailablePhoneNumbersQuery.isLoading}
          slots={{
            noRowsOverlay: PhoneNumberListNoRowsOverlay,
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
          getRowId={row => row.phoneNumber}
          getRowHeight={() => 'auto'}
        />
      </Stack>

      {isPhoneNumberSelectModalOpen && selectedPhoneNumber && (
        <PropertyLocationPhoneNumberAssignModal
          propertyLocationId={Number(id)}
          phoneNumber={selectedPhoneNumber}
          onPhoneNumberSelected={onPhoneNumberSelected}
          onClose={() => setIsPhoneNumberSelectModalOpen(false)}
        />
      )}
    </Stack>
  );
};

export default PropertyLocationPhoneCreatePage;
