import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import DashboardLayout from '@/@mantis/layout/dashboard/DashboardLayout';
import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import { PMPRoutes } from '@/config/routes';
import { MessageNotificationProvider } from '@/context/MessageNotificationProvider';

import LoadingState from '@/components/LoadingState';

import AccountPage from '../shared/AccountPage';

import UnitViewTenantListSubpage from './unit-view/UnitViewTenantListSubpage';

const CalendarView = lazy(() => import('@/components/calendar/CalendarView'));
const BuildingListPage = lazy(() => import('./BuildingListPage'));
const BuildingViewPage = lazy(() => import('./BuildingViewPage'));
const ChecklistPage = lazy(() => import('./ChecklistPage'));
const DashboardPage = lazy(() => import('./DashboardPage'));
const DocumentViewPage = lazy(() => import('./DocumentViewPage'));
const UnitViewPage = lazy(() => import('./UnitViewPage'));
const SubscriptionPage = lazy(() => import('./SubscriptionPage')); // Add the new import
const InboundCallListPage = lazy(() => import('./InboundCallListPage'));
const InboundMessagesPage = lazy(() => import('./InboundMessagesPage'));
const TicketListPage = lazy(() => import('./TicketListPage'));
const PropertyLocationListPage = lazy(() => import('./PropertyLocationListPage'));
const PropertyLocationViewPage = lazy(() => import('./PropertyLocationViewPage'));
const PropertyLocationEmailCreatePage = lazy(() => import('./PropertyLocationEmailCreatePage'));
const PropertyLocationPhoneCreatePage = lazy(() => import('./PropertyLocationPhoneCreatePage'));
const TenantViewPage = lazy(() => import('./TenantViewPage'));
const SentSMSListPage = lazy(() => import('./SentSMSListPage'));
const SentEmailListPage = lazy(() => import('./SentEmailListPage'));
const WorkflowsPage = lazy(() => import('./WorkflowPage'));
const ConversationListPage = lazy(() => import('./ConversationListPage'));
const ConversationViewPage = lazy(() => import('./ConversationViewPage'));
const InvoiceListPage = lazy(() => import('./InvoiceListPage'));
const OperationsHubWorkflowListPage = lazy(() => import('./OperationsHubWorkflowListPage'));
const VaultPage = lazy(() => import('./operations-hub/VaultPage'));

const HomePage: React.FC = () => (
  <MessageNotificationProvider>
    <MuiPageWrapper>
      <DashboardLayout>
        <Suspense fallback={<LoadingState />}>
          <Switch>
            <Route exact path={PMPRoutes.dashboard} component={DashboardPage} />
            <Route exact path={PMPRoutes.calendar} component={CalendarView} />
            <Route exact path={PMPRoutes.buildingList} component={BuildingListPage} />
            <Route exact path={PMPRoutes.documentView} component={DocumentViewPage} />
            <Route
              exact
              path={PMPRoutes.propertyLocationList}
              component={PropertyLocationListPage}
            />

            <Route
              exact
              path={PMPRoutes.propertyLocationEmailCreate}
              component={PropertyLocationEmailCreatePage}
            />

            <Route
              exact
              path={PMPRoutes.propertyLocationPhoneCreate}
              component={PropertyLocationPhoneCreatePage}
            />

            <Route path={PMPRoutes.propertyLocationView} component={PropertyLocationViewPage} />
            <Route
              path={PMPRoutes.propertyLocationViewTenantList}
              component={UnitViewTenantListSubpage}
            />

            <Route path={PMPRoutes.buildingView} component={BuildingViewPage} />
            <Route path={PMPRoutes.unitView} component={UnitViewPage} />
            <Route path={PMPRoutes.tenantView} component={TenantViewPage} />

            <Route path={PMPRoutes.inboundMessages} component={InboundMessagesPage} />
            <Route path={PMPRoutes.account} component={AccountPage} />

            <Route exact path={PMPRoutes.subscription} component={SubscriptionPage} />
            <Route exact path={PMPRoutes.sentEmailList} component={SentEmailListPage} />
            <Route exact path={PMPRoutes.sentSmsList} component={SentSMSListPage} />
            <Route exact path={PMPRoutes.inboundCallList} component={InboundCallListPage} />
            <Route exact path={PMPRoutes.ticketList} component={TicketListPage} />
            <Route exact path={PMPRoutes.conversationList} component={ConversationListPage} />
            <Route exact path={PMPRoutes.conversationView} component={ConversationViewPage} />
            <Route exact path={PMPRoutes.onboardingChecklist} component={ChecklistPage} />
            <Route exact path={PMPRoutes.workflows} component={WorkflowsPage} />
            <Route exact path={PMPRoutes.workflowList} component={OperationsHubWorkflowListPage} />
            <Route exact path={PMPRoutes.invoiceList} component={InvoiceListPage} />
            <Route exact path={PMPRoutes.vault} component={VaultPage} />
            <Route>
              <Redirect to={PMPRoutes.dashboard} />
            </Route>
          </Switch>
        </Suspense>
      </DashboardLayout>
    </MuiPageWrapper>
  </MessageNotificationProvider>
);

export default HomePage;
